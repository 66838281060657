<template>
    <div class="adduser">
        <div class="Basics-form">
            <el-form
                ref="form"
                style="margin-top: 1rem; margin-bottom: 1rem"
                :model="form"
                label-width="80px"
                size="small"
                class="lab"
                label-position="left"
            >
                <el-form-item label="邮箱品牌">
                    <el-radio-group v-model="form.brand">
                        <el-radio :label="1">腾讯</el-radio>
                        <el-radio :label="2">阿里 </el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="所属部门" required>
                    <el-select
                        style="width: 240px"
                        v-model="depmId"
                        clearable
                        placeholder="请选择角色权限"
                    >
                        <el-option
                            v-for="item in depmData"
                            :key="item.id"
                            :label="item.departmentName"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="姓名" required>
                    <el-input
                        v-model="form.nickName"
                        style="width: 240px"
                        placeholder="请输入姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号" required>
                    <el-input
                        disabled
                        v-model="form.userName"
                        style="width: 240px"
                        placeholder="请输入手机号"
                    ></el-input>
                </el-form-item>
                <el-form-item label="身份：" label-width="20%" required>
                    <el-radio-group v-model="form.radio">
                        <el-radio :label="1">普通成员</el-radio>
                        <el-radio :label="2">上级</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="角色权限" required>
                    <el-select
                        style="width: 240px"
                        v-model="form.role"
                        clearable
                        placeholder="请选择角色权限"
                    >
                        <el-option
                            v-for="item in roleData"
                            :key="item.value"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="企业微信" required>
                    <el-input
                        v-model="form.weiXinUserId"
                        style="width: 240px"
                        placeholder="请输入企业微信"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>
        <div class="but">
            <el-button
                @click="shut"
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: auto;
                    padding: 7px 15px !important;
                "
                >取消</el-button
            >

            <el-button
                round
                type="primary"
                style="
                    background: #2370eb;
                    border-color: #2370eb;
                    color: #fff;
                    margin-right: 10px;
                    padding: 7px 15px !important;
                "
                size="mini"
                @click="onSubmitFun"
                >确认</el-button
            >
        </div>
    </div>
</template>

<script>
import { roleList } from '@/api/admin/role/role.js';
import { editUser } from '@/api/admin/framework/framework.js';
import { departmentList } from '@/api/admin/framework/framework.js';
export default {
    props: ['postEidt'],
    data() {
        return {
            data: '',
            depmId: '',
            form: {
                nickName: '',
                userName: '',
                radio: 1,
                role: '',
                weiXinUserId: '',
                brand: 0,
                status: '',
            },
            roleData: [],
            depmData: [],
            btnP: [],
            userType: sessionStorage.getItem('userType'),
        };
    },

    methods: {
        // 获取角色列表
        getRole(row) {
            this.departmentData();
            this.depmId = row.departmentAdminDOList[0].departmentId;

            this.data = row;

            let data = {
                param: {
                    status: 1,
                },
                pageNum: 0,
                pageSize: 0,
            };
            roleList(data).then((res) => {
                if (res.code == 200) {
                    this.roleData = res.data.list;
                }
            });

            this.assignment();
        },
        departmentData() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                this.depmData = res.data;
            });
        },
        // 修改
        onSubmitFun() {
            if (!this.depmId) {
                return this.$message.error('请选择所属部门');
            }
            if (!this.form.nickName) {
                return this.$message.error('请输入姓名');
            } else if (!this.form.userName) {
                return this.$message.error('请输入手机号');
            } else if (!this.form.role) {
                return this.$message.error('请选择角色');
            } else if (!this.form.weiXinUserId) {
                return this.$message.error('请输入企业微信userid');
            } else {
                let departmentAdminDOList = [
                    {
                        departmentId: this.depmId,
                        position: this.form.radio,
                    },
                ];
                let data = {
                    param: {
                        id: this.data.id,
                        nickName: this.form.nickName,
                        userName: this.form.userName,
                        roleId: this.form.role,
                        email: this.form.email,
                        duty: this.form.duty,
                        brand: this.form.brand,
                        weiXinUserId: this.form.weiXinUserId,
                        departmentAdminDOList,
                    },
                };
                editUser(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('修改成功');
                        this.shut();
                    }
                });
            }
        },

        shut() {
            this.$emit('close');
        },
        assignment() {
            this.form.userName = this.data.userName;
            this.form.nickName = this.data.nickName;
            this.form.role = this.data.roleId;
            this.form.email = this.data.email;
            this.form.duty = this.data.duty;
            this.form.weiXinUserId = this.data.weiXinUserId;
            this.form.radio = this.data.departmentAdminDOList[0].position;
            this.form.brand = this.data.brand;
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.adduser {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .Basics {
        margin-left: 1.25rem;
        margin-right: 1rem;
        padding: 0.6rem 0;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        span {
            display: inline-block;
            width: 0.2rem;
            height: 1rem;
            background: #2370eb;
            margin-right: 0.8rem;
        }
    }
    .Basics-form {
        flex: 1;
        padding: 0 30px;
    }
    .el-form-item {
        margin-bottom: 10px;
    }
    .labellist {
        li {
            list-style: none;
            display: inline-block;
            min-width: 4rem;
            padding: 0 0.5rem;
            background: #f1f3f7;
            border-radius: 5px;
            line-height: 2rem;
            text-align: center;
            margin-right: 0.5rem;
            font-size: 0.8rem;
            cursor: pointer;
        }
        .bgcolor {
            background: #2370eb;
            color: #fff;
        }
    }
}

.but {
    width: 100%;
    height: 48px;
    border-top: 1px solid #e5e5e5;
    display: flex;
    align-items: center;
    background: #fff;
    justify-content: space-between;
    button {
        width: 72px;
    }
}
.choiceDept {
    width: 37.5rem;
    height: 37.5rem;
    background: #fff;
    position: fixed;
    z-index: 4;
    left: 50%;
    top: 18%;
    margin-left: -18.8rem;
    border-radius: 5px;
}
.Selectedlist li {
    list-style: none;
    font-size: 0.8rem;
    min-width: 3rem;
    height: 2rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    float: left;
    background: #c5d3f9;
    margin: 0.2rem;
    padding: 0 0.5rem;
    border-radius: 5px;
    color: #fff;
}
</style>
