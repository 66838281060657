<template>
    <div class="edit_box">
        <div
            class="top_input_box"
            :style="{
                background:
                    'url(' + require('@/assets/bg2.png') + ')  no-repeat',
                backgroundSize: 'cover',
            }"
        >
            <h2>
                <i class="iconfont icon-huikuan"></i>
                编辑角色
                <el-button
                    style="
                        background: #fff;
                        border-color: #2370eb;
                        color: #2370eb;
                        padding: 6px 12px;
                        float: right;
                    "
                    size="mini"
                    icon="el-icon-arrow-left"
                    @click="goback"
                >
                    返回列表
                </el-button>
            </h2>
            <p>
                <span>角色名称</span>
                <el-input
                    v-model="oneRole.name"
                    style="width: 240px"
                    size="small"
                    placeholder="请输入内容"
                ></el-input>
                <span style="margin-left: 38px">角色备注</span>
                <el-input
                    v-model="oneRole.remark"
                    size="small"
                    style="width: 240px"
                    placeholder="请输入内容"
                ></el-input>
                <span style="margin-left: 38px">月度销售额度</span>
                <el-input
                    v-model.number="oneRole.expenseAmount"
                    size="small"
                    style="width: 120px"
                    placeholder="请输入内容"
                ></el-input>
                <span>（将在次月更新此销售额度）</span>
            </p>
        </div>
        <div class="tab_box">
            <div class="tab_title">
                <span
                    :class="active == 1 ? 'active' : ''"
                    @click="changeActive(1)"
                >
                    权限配置
                </span>
                <span
                    :class="active == 2 ? 'active' : ''"
                    @click="changeActive(2)"
                >
                    成员列表
                </span>
            </div>
            <div class="tree_box" v-show="active == 1">
                <div class="left_tree">
                    <el-tree
                        :data="leftDataList"
                        ref="tree"
                        :render-content="renderContent"
                        node-key="id"
                        show-checkbox
                        highlight-current
                        :default-expand-all="expandAll"
                        :default-checked-keys="Mrxz"
                        :default-expanded-keys="Mrxz"
                        @node-expand="handleExpand"
                        :props="defaultProps"
                    ></el-tree>
                </div>
                <div class="right_tree">
                    <el-tree
                        :data="rightDataList"
                        ref="tree1"
                        :render-content="renderContent"
                        node-key="id"
                        show-checkbox
                        highlight-current
                        :default-expand-all="expandAll"
                        :default-checked-keys="Mrxz"
                        :default-expanded-keys="Mrxz"
                        @node-expand="handleExpand"
                        :props="defaultProps"
                    ></el-tree>
                </div>
            </div>
            <div class="but" v-show="active == 1">
                <el-button
                    type="plain"
                    size="small"
                    round
                    style="background: #fff"
                    @click="goback"
                    >取消</el-button
                >
                <el-button
                    type="primary"
                    size="small"
                    round
                    style="background: #2370eb"
                    @click="onSubmit"
                    >保存</el-button
                >
            </div>
            <div class="tree_box2" v-show="active == 2">
                <Table ref="table"></Table>
            </div>
        </div>
    </div>
</template>

<script>
import { adminMenuList, roleUpdate, roleGet } from '@/api/admin/role/role.js';
import Table from './roleTable.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            Mrxz: [],
            expandAll: false,
            checkedKeys: [],
            defaultProps: {
                label: 'name',
                children: 'children',
            },
            leftDataList: [],
            rightDataList: [],
            data: {},
            input: '',
            active: 1,
            oneRole: {},
        };
    },
    components: {
        Table,
    },
    created() {},
    mounted() {
        this.modify();
    },
    methods: {
        //    getData(data) {
        //        this.data = data;
        //        this.modify();
        //    },
        modify() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            this.Mrxz = [];
            adminMenuList(data).then((res) => {
                let list = this.$Tree.treeDataTranslateAudit(
                    res.data,
                    'id',
                    'parentId'
                );
                var lastchildren = [];
                forxh(list);
                function forxh(list) {
                    for (var i = 0; i < list.length; i++) {
                        var chlist = list[i];
                        if (chlist.children && chlist.children.length > 0) {
                            forxh(chlist.children);
                        } else {
                            lastchildren.push(chlist);
                        }
                    }
                }
                for (let j = 0; j < lastchildren.length; j++) {
                    this.Mrxz.push(lastchildren[j].id);
                }
                this.getMenu();
                this.roleGet();
            });
        },
        // 获取菜单
        getMenu() {
            let data = {
                param: {},
            };
            adminMenuList(data).then((res) => {
                this.dataList = this.$Tree.treeDataTranslateAudit(
                    res.data,
                    'id',
                    'parentId'
                );
                var groupedArray = [];
                if (this.dataList % 2 == 0) {
                    groupedArray = this.group(
                        this.dataList,
                        parseInt(this.dataList.length / 2)
                    );
                } else {
                    groupedArray = this.group(
                        this.dataList,
                        parseInt(this.dataList.length / 2) + 1
                    );
                }
                this.leftDataList = groupedArray[0];
                this.rightDataList = groupedArray[1];
            });
        },
        roleGet() {
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            roleGet(data).then((res) => {
                if (res.code == 200) {
                    console.log(res);
                    this.oneRole = res.data;
                }
            });
        },
        handleExpand() {},
        renderContent(h, { node, data, store }) {
            //树节点的内容区的渲染 Function

            let classname = '';
            // 由于项目中有三级菜单也有四级级菜单，就要在此做出判断

            if (node.level === 2 && node.childNodes.length === 0) {
                classname = 'foo';
            }
            if (node.level === 1 && node.childNodes.length === 0) {
                classname = 'foo';
            }
            if (node.level === 3 && node.childNodes.length === 0) {
                classname = 'foo';
            }
            return h(
                'p',
                {
                    class: classname,
                },
                node.label
            );
        },
        group(array, subGroupLength) {
            let index = 0;
            let newArray = [];
            while (index < array.length) {
                newArray.push(array.slice(index, (index += subGroupLength)));
            }
            return newArray;
        },
        changeActive(i) {
            this.active = i;
            if (i == 2) {
                this.$refs.table.getData();
            } else {
                this.modify();
            }
        },
        onSubmit() {
            var addRoleArr = [];
            let res = this.$refs.tree.getHalfCheckedKeys();
            let res1 = this.$refs.tree.getCheckedKeys();
            let res2 = res.concat(res1);
            let res3 = this.$refs.tree1.getHalfCheckedKeys();
            let res4 = this.$refs.tree1.getCheckedKeys();
            let res5 = res3.concat(res4);
            let res6 = res2.concat(res5);
            res6.forEach((item) => {
                addRoleArr.push({
                    menuId: item,
                    roleId: this.$route.query.id,
                });
            });
            console.log(addRoleArr);
            if (this.oneRole.name == '') {
                this.$message.error('角色名称不能为空');
            } else if (addRoleArr.length == 0) {
                return this.$message.error('角色权限不能为空');
            }
            {
                // console.log(this.addRoleArr);
                let data = {
                    param: {
                        name: this.oneRole.name,
                        remark: this.oneRole.remark,
                        id: this.$route.query.id,
                        roleMenuDOList: addRoleArr,
                        expenseAmount: this.oneRole.expenseAmount,
                    },
                };
                roleUpdate(data).then((res) => {
                    if (res.code == 200) {
                        this.goback();
                        this.$message.success('修改角色成功');
                    }
                });
            }
        },
        goback() {
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
.edit_box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    .top_input_box {
        padding: 16px 16px 20px;
        margin-bottom: 12px;
        box-sizing: border-box;
        h2 {
            font-size: 16px;

            i {
                color: #2370eb;
                font-weight: normal;
            }
            button {
                float: right;
            }
        }
        > p {
            padding-left: 20px;
            font-size: 14px;
            margin-top: 16px;
            span {
                margin-right: 16px;
            }
        }
    }
    .tab_box {
        height: calc(100% - 125px);
        .tab_title {
            height: 52px;
            box-sizing: border-box;
            border-bottom: 1px solid #e5e5e5;
            padding-left: 20px;
            display: flex;
            background-color: #fff;
            span {
                font-size: 14px;
                color: #333;
                display: block;
                height: 52px;
                line-height: 52px;
                cursor: pointer;
            }
            span.active {
                color: #2370eb;
                position: relative;
            }
            .active:before {
                content: '';
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                width: 32px;
                height: 2px;
                background: #2370eb;
            }
            span + span {
                margin-left: 44px;
            }
        }
        .tree_box {
            display: flex;

            height: calc(100% - 100px);
            justify-content: space-between;
            > div {
                padding: 20px;
                background-color: #fff;
                box-sizing: border-box;
                width: 49.8%;
                height: 100%;
                overflow-y: auto;
            }
        }
        .tree_box2 {
            display: flex;
            height: calc(100% - 52px);
            justify-content: space-between;
            > div {
                padding: 20px;
                background-color: #fff;
                box-sizing: border-box;
                width: 49.8%;
                height: 100%;
                overflow-y: auto;
            }
        }
    }
    .but {
        width: 100%;
        height: 52px;
        box-sizing: border-box;
        border-top: 1px solid #e5e5e5;
        display: flex;
        align-items: center;
        background: #fff;
        position: relative;
        z-index: 200;
        justify-content: center;
        button {
            padding: 6px 28px;
        }
    }
}
</style>
