<template>
    <div class="table-box" style="width: 100%; padding: 0">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '500',
            }"
        >
            <el-table-column width="10" show-overflow-tooltip>
            </el-table-column>
            <el-table-column label="姓名" show-overflow-tooltip width="135">
                <template slot-scope="scope">
                    <div>
                        {{ scope.row.nickName ? scope.row.nickName : '- -' }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="部门" show-overflow-tooltip width="100">
                <template slot-scope="scope">
                    <div>
                        {{
                            scope.row.departmentName
                                ? scope.row.departmentName
                                : '- -'
                        }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>

            <el-table-column label="操作" width="130" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onEdit(scope.row)"
                        >编辑</el-button
                    >
                    <el-button
                        style="color: #d0021b"
                        type="text"
                        v-if="scope.row.status == 1 && scope.row.type != 2"
                        @click="handleDelete(scope.row)"
                        >禁用</el-button
                    >
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        v-if="scope.row.status == 0"
                        @click="handleDelete(scope.row)"
                        >启用</el-button
                    >
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
        <!-- x修改 -->
        <el-drawer
            style="border: none; outline: none"
            :visible.sync="drawerEdit"
            :direction="direction"
            :before-close="handleClose"
            size="30rem"
        >
            <template slot="title">
                <div
                    style="
                        flex: 1;
                        display: flex;
                        align-items: center;
                        color: #101010;
                    "
                >
                    <span class="vertical"></span>
                    编辑成员
                </div>
            </template>
            <EditUser ref="editUser" @close="handleClose" />
        </el-drawer>
    </div>
</template>

<script>
import { roleList } from '@/api/customer/customer.js';
import { roleMemberGet } from '@/api/admin/role/role.js';
import { statusUser } from '@/api/admin/framework/framework.js';
import EditUser from './editMember.vue';
export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            tableRow: {},
            drawerEdit: false,
            btnP: {},
            pageData: {},
            direction: 'rtl',
        };
    },
    components: {
        EditUser,
    },
    created() {},
    methods: {
        getData() {
            let data = {
                param: {
                    roleType: this.$route.query.id,
                    all: 1,
                },
                pageSize: 0,
                pageNum: 0,
            };
            roleList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data;
                }
            });
        },
        onEdit(row) {
            this.drawerEdit = true;
            let data = {
                param: {
                    id: row.id,
                },
            };
            roleMemberGet(data).then((res) => {
                if (res.code == 200) {
                    this.$refs.editUser.getRole(res.data);
                }
            });
            // setTimeout(() => {
            //
            // }, 0);
        },
        onDelete(data) {
            this.$emit('onDelete', data);
        },
        handleClose() {
            this.drawerEdit = false;
            this.getData();
        },
        // 禁用启用
        handleDelete(row) {
            let data = {
                param: {
                    adminIdList: [row.id],
                },
            };
            if (row.status == 1) {
                data.param.status = 0;
                this.$confirm('禁用后当前账号不可登录，是否禁用', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        statusUser(data).then((res) => {
                            if (res.code == 200) {
                                this.getData();
                                this.$message.success('禁用成功');
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消禁用',
                        });
                    });
            } else {
                data.param.status = 1;
                statusUser(data).then((res) => {
                    if (res.code == 200) {
                        this.getData();
                        this.$message.success('启用成功');
                    }
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;
    .icon-ziyuanxhdpi {
        color: #2370eb;
        cursor: pointer;
        vertical-align: -1px;
    }
}

/deep/ .el-table .cell {
    text-overflow: initial;
    padding-right: 0;
}
.dl_con {
    width: 80%;
    margin: 35px auto 100px;
    text-align: center;
    color: #333;
    font-weight: 600;
    font-size: 14px;
    i {
        font-weight: 400;
        font-size: 60px;
        color: #f5a623;
        display: block;
        margin-bottom: 20px;
    }
}
/deep/.el-dialog__footer {
    padding: 0;
    text-align: right;
    height: 50px;
    line-height: 50px;
    border-top: 1px solid #eeeeee;
    padding-right: 18px;
    .el-button--primary {
        background-color: #2370eb;
    }
}
</style>
